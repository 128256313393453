import consumer from "./consumer"

consumer.subscriptions.create("ApperanceChannel", {
  initialized() {
    this.update = this.update.bind(this)
  },
  connected() {
    this.online()
    // this.install()
    // this.update()
  },

  disconnected() {
    this.online()
    // this.uninstall()
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log("received", data);
    $(`#user-status-${data["user_id"]}`).removeClass("status-online");
    $(`#user-status-${data["user_id"]}`).removeClass("status-offline");
    $(`#user-status-${data["user_id"]}`).removeClass("status-away");

    $(`#user-status-${data["user_id"]}`).addClass(`status-${data["appearance"]}`);
  },
  // Called when the subscription is rejected by the server.
  rejected() {
    this.uninstall()
  },

  update() {
    // this.documentIsActive ? this.online() : this.away()
    this.online()
  },

  online() {
    // Calls `AppearanceChannel#online(data)` on the server.
    console.log("online")
    this.perform("online", { appearing_on: this.appearingOn })
  },

  away() {
    // Calls `AppearanceChannel#away` on the server.
    console.log("away")
    // this.perform("away")
  },

  install() {
    window.addEventListener("focus", this.update)
    window.addEventListener("blur", this.update)
    document.addEventListener("turbolinks:load", this.update)
    document.addEventListener("visibilitychange", this.update)
  },

  uninstall() {
    window.removeEventListener("focus", this.update)
    window.removeEventListener("blur", this.update)
    document.removeEventListener("turbolinks:load", this.update)
    document.removeEventListener("visibilitychange", this.update)
  },

  get documentIsActive() {
    return document.visibilityState === "visible" && document.hasFocus()
  },

  get appearingOn() {
    return new Date()
  }
});
