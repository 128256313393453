import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []
  static values = { paper:String, file:String }

  initialize() {
    // console.log("controller initialize");
  }

  connect() {
    setInterval( ()=> {
      this.lockPaper();
      // console.log("locked ping");
    }
    , 15000);
  }

  disconnect() {
  }

  lockPaper() {
    const url = `/audit_files/${this.fileValue}/audit_papers/${this.paperValue}/lock`
    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    let xhr = new XMLHttpRequest();
    // Will be a GET request here
    xhr.open('GET', url, false);
    // Setting the headers
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('X-CSRF-Token', csrf);
    xhr.setRequestHeader('dataType', 'script');
    // What to do with the response
    xhr.onload = function() {
      let responseObj = xhr.response
      eval(responseObj);
    }
    // Sending the request
    xhr.send()
  }
}
