import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "text" ]
  static values = { }

  initialize() {
  }

  connect() {

  }

  setText(event)
  {
    this.textTarget.innerHTML = `WP-${event.target.selectedIndex+1}`
  }
}
