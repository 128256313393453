import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "newLink", "editLink", "contract" ]
  static values = { }

  initialize() {
  }

  connect() {
    this.contractTarget.addEventListener("change", (event) => {
      this.setContract();
    });
    this.setContract();
  }

  setContract() {
    console.log(this.contractTarget.value);

    console.log(this.newLinkTarget.href);
    this.newLinkTarget.href =
      this.newLinkTarget.href.replace(/contract_id=\d+/i, `contract_id=${this.contractTarget.value}`);
    console.log(this.newLinkTarget.href);

    for(var link of this.editLinkTargets){
      console.log(link.href);
      link.href = link.href.replace(/contract_id=\d+/i, `contract_id=${this.contractTarget.value}`);
      console.log(link.href);
    }
  }
}
