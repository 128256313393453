import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "search", "link" ]
  static values = { }

  initialize() {
  }

  connect() {
  }

  search(event)
  {
    let text = event.currentTarget.value.toLowerCase();
    for(var link of this.linkTargets)
    {
      if(link.textContent.toLowerCase().search(text) != -1)
      {
        link.closest(".nav-item").classList.remove("d-none");
        if(text)
          link.closest(".nav").classList.add("show");
        else
          link.closest(".nav").classList.remove("show");
      }
      else
      {
        link.closest(".nav-item").classList.add("d-none");
      }
    }
  }

  strike(event)
  {

  }
}
