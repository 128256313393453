import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "add", "remove" ]
  static values = { field: String }

  initialize() {
  }

  connect() {
  }

  add(event)
  {
    let tr = event.target.closest("tr");
    tr.classList.add("bg-soft-success");
    $(tr).find(".add-button").addClass("d-none");
    $(tr).find(".remove-button").removeClass("d-none");
    $(tr).find(".risk-input").prop("disabled", false);
  }

  remove(event)
  {
    let tr = event.target.closest("tr");
    tr.classList.remove("bg-soft-success");
    $(tr).find(".add-button").removeClass("d-none");
    $(tr).find(".remove-button").addClass("d-none");
    $(tr).find(".risk-input").prop("disabled", true);
  }

  submit(event)
  {
    console.log(event);
    document.getElementById("risks-form").requestSubmit();
    $(event.target.closest(".modal")).modal("toggle");
  }
}
