import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]
  static values = { }

  initialize() {
  }

  connect() {
  }

  showDeleted(event)
  {
    let elements = document.querySelectorAll(".audit-paper.text-danger");
    for(var element of elements)
    {
      if(event.currentTarget.checked){
        element.classList.remove("d-none");
        let children = element.querySelectorAll(".text-danger");
        for(var child of children)
          child.classList.remove("d-none");
      }
      else{
        element.classList.add("d-none");
        let children = element.querySelectorAll(".text-danger");
        for(var child of children)
          child.classList.add("d-none");
      }
    }
  }
}
