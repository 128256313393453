import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "row" ]
  static values = { }

  initialize() {
    console.log("search")
  }

  connect() {

  }

  search()
  {
    let val = this.inputTarget.value.toLowerCase();
    for(var row of this.rowTargets)
    {
      if(row.innerText.toLowerCase().includes(val))
      {
        row.closest("tr").classList.remove("d-none");
      }
      else
      {
        row.closest("tr").classList.add("d-none");
      }
    }
  }
}
