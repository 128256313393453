import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select", "gregorian", "hijri" ]
  static values = { }

  initialize() {
  }

  connect() {
    this.applyCalendar();
  }

  applyCalendar()
  {
    console.log(this.selectTarget.value);
    if(this.selectTarget.value == "hijri" || this.selectTarget.value == "hijri1")
    {
      this.gregorianTarget.disabled = true;
      this.gregorianTarget.style.transform = "scale(0,0)";
      this.hijriTarget.disabled = false;
      this.hijriTarget.style.transform = "initial";
    }
    else
    {
      this.gregorianTarget.disabled = false;
      this.gregorianTarget.style.transform = "initial";
      this.hijriTarget.disabled = true;
      this.hijriTarget.style.transform = "scale(0,0)";
    }
  }
}
