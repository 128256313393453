import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "url" ]
  static values = { dir: String }

  initialize() {
    window.tinymce.init({
      selector: '.audit-tinymce',
      height: '50vh',
      menubar: false,
      skin: "oxide",
      content_style: ".mce-content-body { color: #000 }",
      mobile: {
        theme: 'mobile',
        toolbar: ['undo', 'bold']
      },
      statusbar: false,
      plugins: 'lists',
      directionality : this.dirValue,
      toolbar: 'styleselect | bold italic bullist numlist undo redo',
      setup: (editor) => {
        editor.on('blur', function(e) {
          console.log('The Editor has blur.');
          Rails.fire($("#docForm")[0], 'submit');
        });
      }
    });
  }

  connect() {

  }
}
