import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select", "template", "tr", "tbody", "table" ]
  static values = { }

  initialize() {
  }

  connect() {
    this.updateSelect();
    $(this.selectTarget).on("change", (e) => {
      this.addMember();
    });
  }

  addMember()
  {
    let user = this.selectTarget.selectedOptions[0];
    this.selectTarget.value = "";

    var template = this.templateTarget.content.cloneNode(true);
    template.getElementById("userName").textContent = user.label;
    template.getElementById("userRole").textContent = user.getAttribute("data-role");
    template.querySelector(".adminIcon").classList.add("d-none");

    template.getElementById("audit_file_members_attributes__user_id").value = user.value;
    template.getElementById("audit_file_members_attributes__role").value = "file_auditor";

    this.destroyDatatable();
    this.tbodyTarget.insertBefore(template, this.trTarget);
    this.createDatatable();

    this.updateSelect();
  }

  assignAdmin(event)
  {
    let tr = event.currentTarget.closest("tr");

    let icons = document.querySelectorAll(".adminIcon");
    for(var icon of icons)
      icon.classList.add("d-none");

    let assigns = document.querySelectorAll(".assignAdmin");
    for(var assign of assigns)
      assign.classList.remove("disabled");

    let values = document.querySelectorAll("#audit_file_members_attributes__role");
    for(var value of values)
      value.value = "file_auditor";

    tr.querySelector("#audit_file_members_attributes__role").value = "file_admin";
    tr.querySelector(".adminIcon").classList.remove("d-none");
    tr.querySelector(".assignAdmin").classList.add("disabled");
  }

  deleteUser(event)
  {
    let tr = event.currentTarget.closest("tr");

    if(tr.querySelector("#audit_file_members_attributes__id").value)
    {
      tr.querySelector("#audit_file_members_attributes___destroy").value = true;
      tr.classList.add("d-none");
    }
    else
    {
      this.destroyDatatable();
      this.tbodyTarget.removeChild(tr);
      this.createDatatable();
    }

    this.updateSelect();
  }

  updateSelect()
  {
    var selectedUsers = this.tbodyTarget.querySelectorAll("#audit_file_members_attributes__user_id");

    for(var option of this.selectTarget.options)
      option.classList.remove("d-none");

    // start with 1 as the empty option is in select
    var count = 1;
    for(var option of this.selectTarget.options)
    {
      for (var user of selectedUsers)
      {
        if(user.value == option.value &&
           user.closest("tr").querySelector("#audit_file_members_attributes___destroy").value != "true")
        {
          option.classList.add("d-none");
          count += 1;
        }
      }
    }

    if(count == this.selectTarget.options.length)
      this.trTarget.classList.add("d-none");
    else
      this.trTarget.classList.remove("d-none");
  }

  destroyDatatable()
  {
    this.dtPage = $(this.tableTarget).DataTable().page();
    $(this.tableTarget).dataTable().fnDestroy();
  }

  createDatatable()
  {
    var customDataTable = function customDataTable(elem) {
      elem.find('.pagination').addClass('pagination-sm');
    };

    var $this = $(this.tableTarget);
    var options = $.extend({
      responsive: true,
      aaSorting: [],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    }, $this.data('options'));
    $this.DataTable(options);
    var $wrpper = $this.closest('.dataTables_wrapper');
    customDataTable($wrpper);
    $this.on('draw.dt', function () {
      return customDataTable($wrpper);
    });

    $(this.tableTarget).DataTable().page(this.dtPage).draw(false);
  }
}
