import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "calendar" ]
  static values = { date: String }

  initialize() {
  }

  connect() {
    flatpickr(this.calendarTarget, { wrap: true, defaultDate: this.dateValue });
  }
}
