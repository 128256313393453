import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]
  static values = { parent: String, matcher: Boolean }

  initialize() {
  }

  connect() {
    var options = {
      theme: "bootstrap4",
      tokenSeparators: [','],
      templateResult: function (state) {
        let $opt = $(state.element);
        if ($opt.hasClass("d-none")) {
          return null;
        }
        return state.text;
      }
    };
    if(this.matcherValue == true)
      options["matcher"] = this.matcher;

    if(this.parentValue) {
      options.dropdownParent = $(`#${this.parentValue}`);
    }
    $(this.element).select2(options);

    $(this.element).on("select2:unselect", (e) => {
      console.log(e);
    });

    $(this.element).on("change", (e) => {
      // console.log("change", e);
    });

    $(this.element).on('select2:open', (e) => {
      document.querySelector('.select2-search__field').focus();
    });
  }

  matcher(params, data) {
    console.log(params);
    console.log(data);
    if ($.trim(params.term) === '') {
      return data;
    }
    if (data.text.toUpperCase().indexOf(params.term.toUpperCase()) != -1)
      return data;
    else
      return null;
  }
}
