import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]
  static values = { title: String, placement: String }

  initialize() {
  }

  connect() {
    this.tooltip = $(this.element).tooltip({
      placement: this.data.get("placement"),
      title: this.data.get("title")
    });
    $(this.element).on("click", (e) => {
      $(this.element).tooltip("hide");
    });
  }
}
