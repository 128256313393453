import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "text", "input" ]
  static values = { field: String }

  initialize() {
  }

  connect() {
  }

  select(event)
  {
    let tr = event.target.closest("tr");
    $(`#${this.fieldValue} #conclusion-input`).val($(tr).find("input[name='id']").val());
    $(`#${this.fieldValue} #conclusion-input`).trigger("change");
    $(`#${this.fieldValue} #conclusion-text`).html($(tr).find("input[name='description']").val());
    $(tr.closest(".modal")).modal("toggle");
  }

  remove(event)
  {
    let tr = event.target.closest("tr");
    $(`#${this.fieldValue} #conclusion-input`).val("");
    $(`#${this.fieldValue} #conclusion-input`).trigger("change");
    $(`#${this.fieldValue} #conclusion-text`).html("");
    $(tr.closest(".modal")).modal("toggle");
  }
}
