import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "textArea"]
  static values = { file:String }

  connect() {
    this.tribute = new Tribute({
      collection: [
        {
          // symbol or string that starts the lookup
          trigger: '@',
          // column to search against in the object (accepts function or string)
          lookup: 'name',
          // column that contains the content to insert by default
          fillAttr: 'username',
          // REQUIRED: array of objects to match or a function that returns data (see 'Loading remote data' for an example)
          values: (text, cb) => this.fetchUsers(text, cb),
          // When your values function is async, an optional loading template to show
          loadingItemTemplate: this.loadingTemplate(),
          // Limits the number of items in the menu
          menuItemLimit: 25,
          // specify the minimum number of characters that must be typed before menu appears
          menuShowMinLength: 0,
          selectTemplate: (item) => {
            return this.userTemplate(item);
          },
          allowSpaces: false,
          replaceTextSuffix: '',
        },
        {
          // symbol or string that starts the lookup
          trigger: '#',
          // column to search against in the object (accepts function or string)
          lookup: 'filename',
          // column that contains the content to insert by default
          fillAttr: 'filename',
          // REQUIRED: array of objects to match or a function that returns data (see 'Loading remote data' for an example)
          values: (text, cb) => this.fetchDocuments(text, cb),
          // When your values function is async, an optional loading template to show
          loadingItemTemplate: this.loadingTemplate(),
          // Limits the number of items in the menu
          menuItemLimit: 25,
          // specify the minimum number of characters that must be typed before menu appears
          menuShowMinLength: 0,
          selectTemplate: (item) => {
            return this.documentTemplate(item);
          },
          allowSpaces: false,
          replaceTextSuffix: '',
        },
        {
          // symbol or string that starts the lookup
          trigger: '!',
          // column to search against in the object (accepts function or string)
          lookup: 'name_code',
          // column that contains the content to insert by default
          fillAttr: 'name_code',
          // REQUIRED: array of objects to match or a function that returns data (see 'Loading remote data' for an example)
          values: (text, cb) => this.fetchPapers(text, cb),
          // When your values function is async, an optional loading template to show
          loadingItemTemplate: this.loadingTemplate(),
          // Limits the number of items in the menu
          menuItemLimit: 60,
          // specify the minimum number of characters that must be typed before menu appears
          menuShowMinLength: 0,
          selectTemplate: (item) => {
            return this.paperTemplate(item);
          },
          allowSpaces: false,
          replaceTextSuffix: '',
        }
      ]
    });

    this.tribute.attach(this.inputTarget);

    this.inputTarget.addEventListener("blur", (e) => {
      if(this.inputTarget.innerHTML != this.textAreaTarget.value)
      {
        this.textAreaTarget.value = this.inputTarget.innerHTML;
        this.textAreaTarget.onchange();
      }
    })
  }

  userTemplate(item)
  {
    return `<a href="${item.original.url}" contenteditable="false"><span class="user-mentions badge badge-pill badge-info" data-username="${item.original.username}">@${item.original.name}</span></a>`;
  }

  documentTemplate(item)
  {
     return `<a href="${item.original.url}" contenteditable="false"><span class="document-mentions badge badge-pill badge-secondary">#${item.original.filename}</span></a>`;
  }

  paperTemplate(item)
  {
     return `<a href="${item.original.url}" contenteditable="false"><span class="document-mentions badge badge-pill badge-success">!${item.original.name_code}</span></a>`;
  }

  loadingTemplate() {
    return '<i class="fas fa-spinner fa-spin"></i>';
  }

  fetchUsers(text, cb) {
    return fetch(`/audit_files/${this.fileValue}/audit_file_users.json?q=${text}`)
           .then((response) => response.json())
           .then((responseData) => {
             console.log(responseData);
             return cb(responseData);
           })
  }

  fetchDocuments(text, cb) {
    return fetch(`/audit_files/${this.fileValue}/audit_file_documents.json?q=${text}`)
           .then((response) => response.json())
           .then((responseData) => {
             console.log(responseData);
             return cb(responseData);
           })
  }

  fetchPapers(text, cb) {
    return fetch(`/audit_files/${this.fileValue}/audit_papers.json?q=${text}`)
           .then((response) => response.json())
           .then((responseData) => {
             console.log(responseData);
             return cb(responseData);
           })
  }
}
