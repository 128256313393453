import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]
  static values = { }

  initialize() {
  }

  connect() {
  }

  toggleAll()
  {
    var toggles = $(".account-fields");
    var flag = 0; // no one is show
    for(var toggle of toggles)
    {
      console.log(toggle);
      if($(toggle).hasClass("show"))
        flag = 1;
    }
    if(flag == 0)
      $(".account-fields").collapse("show");
    else
      $(".account-fields").collapse("hide");
  }

  toggleEmpty()
  {
    $(".account-fields.empty").collapse("toggle");
  }
}
