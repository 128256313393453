import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "headerIndex", "accountNo", "accountName",
                     "currentYearBalance", "priorYearBalance" ]
  static values = { }

  initialize() {
    // console.log("map-trial-balance");
  }

  connect() {

  }

  headerIndexChange(event)
  {
    let row_index = this.headerIndexTarget.value;
    let row_text = this.headerIndexTarget.options[this.headerIndexTarget.selectedIndex].text;
    let columns = row_text.split(",");

    this.createOptions(this.accountNoTarget, columns);
    this.createOptions(this.accountNameTarget, columns);
    this.createOptions(this.currentYearBalanceTarget, columns);
    this.createOptions(this.priorYearBalanceTarget, columns);
    // this.createOptions(this.priorYear2BalanceTarget, columns);
  }

  createOptions(select, columns)
  {
    let index = select.options.length;
    while(index-- > 1) {
      select.remove(index);
    }

    // create empty option
    // var option = document.createElement("option");
    // option.text = "";
    // select.add(option);

    for(var i = 0; i < columns.length; i++)
    {
      var option = document.createElement("option");
      option.text = columns[i];
      option.value = i;
      select.add(option);
    }
  }
}
