import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "label" ]
  static values = { }

  initialize() {
  }

  connect() {
    this.inputTarget.addEventListener("change", e => {
      this.labelTarget.innerText = e.target.files[0].name;
    })
  }
}
