import consumer from "./consumer"

consumer.subscriptions.create(
  { channel: "Noticed::NotificationChannel"},
  {
    received(data) {
      console.log("notification:", data);
      $("#notifications").html(data["message"]);
      $(".notification-toast").toast({"delay": 3000});
      $(".notification-toast").toast("show");

      $("#nav-notifications").addClass("notification-indicator notification-indicator-primary");
    }
  }
)
