import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item", "input" ]
  static values = { }

  initialize() {
  }

  connect() {

  }

  destroy(event)
  {
    this.destroyDatatable();
    this.itemTarget.classList.add("d-none");
    this.inputTarget.value = "true";
    this.createDatatable();
  }

  destroyDatatable()
  {
    this.dtPage = $(this.itemTarget.closest("table")).DataTable().page();
    $(this.itemTarget.closest("table")).dataTable().fnDestroy();
  }

  createDatatable()
  {
    var customDataTable = function customDataTable(elem) {
      elem.find('.pagination').addClass('pagination-sm');
    };

    var $this = $(this.itemTarget.closest("table"));
    var options = $.extend({
      responsive: true,
      aaSorting: [],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    }, $this.data('options'));
    $this.DataTable(options);
    var $wrpper = $this.closest('.dataTables_wrapper');
    customDataTable($wrpper);
    $this.on('draw.dt', function () {
      return customDataTable($wrpper);
    });

    $(this.itemTarget.closest("table")).DataTable().page(this.dtPage).draw(false);
  }
}
