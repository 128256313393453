import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "template", "tbody", "table" ]
  static values = { }

  initialize() {
  }

  connect() {
  }

  openAddModal()
  {
    this.modalTarget.querySelector("#rowIndex").value = "";
    this.modalTarget.querySelector("#name_en").value = "";
    this.modalTarget.querySelector("#name_ar").value = "";
    this.modalTarget.querySelector("#mandatory").value = "";
    this.modalTarget.querySelector("#mandatory").checked = false;

    // modal in add mode
    this.modalTarget.querySelector("#addHeader").classList.remove("d-none");
    this.modalTarget.querySelector("#addButton").classList.remove("d-none");

    this.modalTarget.querySelector("#editHeader").classList.add("d-none");
    this.modalTarget.querySelector("#editButton").classList.add("d-none");
    $(this.modalTarget).modal('toggle');
  }

  openEditModal(event)
  {
    let tr = event.currentTarget.closest("tr");
    this.modalTarget.querySelector("#rowIndex").value = tr.rowIndex;

    this.modalTarget.querySelector("#name_en").value =
      tr.querySelector("#audit_file_checklists_attributes__name_en").value;
    this.modalTarget.querySelector("#name_ar").value =
      tr.querySelector("#audit_file_checklists_attributes__name_ar").value;
    this.modalTarget.querySelector("#mandatory").checked =
      (tr.querySelector("#audit_file_checklists_attributes__mandatory").value == "true")? true : false;

    // modal in edit mode
    this.modalTarget.querySelector("#addHeader").classList.add("d-none");
    this.modalTarget.querySelector("#addButton").classList.add("d-none");

    this.modalTarget.querySelector("#editHeader").classList.remove("d-none");
    this.modalTarget.querySelector("#editButton").classList.remove("d-none");
    $(this.modalTarget).modal('toggle');
  }

  addItem()
  {
    var template = this.templateTarget.content.cloneNode(true);

    if(this.assignValues(template) == false)
      return;

    // $(this.tableTarget).DataTable().row.add(template).draw(false);
    this.destroyDatatable();
    this.tbodyTarget.append(template);
    this.createDatatable();
    // modal in add mode
    $(this.modalTarget).modal('toggle');
  }

  deleteItem()
  {
    let tr = event.currentTarget.closest("tr");
    if(tr.querySelector("#audit_file_checklists_attributes__id").value)
    {
      tr.querySelector("#audit_file_checklists_attributes___destroy").value = true;
      tr.classList.add("d-none");
    }
    else
    {
      this.destroyDatatable();
      this.tbodyTarget.removeChild(tr);
      this.createDatatable();
    }
  }

  editItem()
  {
    let rowIndex = this.modalTarget.querySelector("#rowIndex").value;
    let tr;

    for (var t of this.tbodyTarget.querySelectorAll("tr"))
    {
      console.log(t.rowIndex.toString(), rowIndex);
      if(t.rowIndex.toString() == rowIndex)
      {
        tr = t;
        break;
      }
    }

    if(this.assignValues(tr) == false)
      return;

    // modal in add mode
    $(this.modalTarget).modal('toggle');
  }

  invalidInputs()
  {
    let invalid = false;
    let name_en = this.modalTarget.querySelector("#name_en").value;
    let name_ar = this.modalTarget.querySelector("#name_ar").value;

    if(name_en == "")
    {
      this.modalTarget.querySelector("#name_en").classList.add("is-invalid");
      invalid = true;
    }
    else
      this.modalTarget.querySelector("#name_en").classList.remove("is-invalid");

    if(name_ar == "")
    {
      this.modalTarget.querySelector("#name_ar").classList.add("is-invalid");
      invalid = true;
    }
    else
      this.modalTarget.querySelector("#name_ar").classList.remove("is-invalid");

    return invalid;
  }

  assignValues(element)
  {
    // validate name_en, name_ar exist
    let name_en = this.modalTarget.querySelector("#name_en").value;
    let name_ar = this.modalTarget.querySelector("#name_ar").value;

    if(this.invalidInputs() == true)
      return false;

    let mandatory = this.modalTarget.querySelector("#mandatory").getAttribute("data-false");
    if(this.modalTarget.querySelector("#mandatory").checked)
      mandatory = this.modalTarget.querySelector("#mandatory").getAttribute("data-true");

    element.querySelector("#checklistName").textContent = name_en;
    element.querySelector("#audit_file_checklists_attributes__name_en").value = name_en;
    element.querySelector("#audit_file_checklists_attributes__name_ar").value = name_ar;
    element.querySelector("#checklistMandatory").textContent = mandatory;
    element.querySelector("#audit_file_checklists_attributes__mandatory").value =
      this.modalTarget.querySelector("#mandatory").checked;

    return true;
  }

  selectFile(event)
  {
    let tr = event.currentTarget.closest("tr");
    let file = Array.from(event.currentTarget.files)[0];

    tr.querySelector("#fileName").textContent = file.name;
    tr.querySelector("#fileName").classList.remove("d-none");
    tr.querySelector("#fileNameBlank").classList.add("d-none");

    tr.querySelector("#fileUploaded").classList.remove("d-none");
    tr.querySelector("#filePending").classList.add("d-none");

    tr.querySelector("#clearFile").classList.remove("d-none");
    tr.querySelector("#uploadFile").classList.add("d-none");

    tr.querySelector("#delete_documents___destroy").value = false;
  }

  clearFile(event)
  {
    let tr = event.currentTarget.closest("tr");

    tr.querySelector("#fileName").textContent = "file.name";
    tr.querySelector("#fileName").classList.add("d-none");
    tr.querySelector("#fileNameBlank").classList.remove("d-none");

    tr.querySelector("#fileUploaded").classList.add("d-none");
    tr.querySelector("#filePending").classList.remove("d-none");

    tr.querySelector("#clearFile").classList.add("d-none");
    tr.querySelector("#uploadFile").classList.remove("d-none");

    tr.querySelector("#fileInput").value = "";

    tr.querySelector("#delete_documents___destroy").value = true;
  }

  destroyDatatable()
  {
    this.dtPage = $(this.tableTarget).DataTable().page();
    $(this.tableTarget).dataTable().fnDestroy();
  }

  createDatatable()
  {
    var customDataTable = function customDataTable(elem) {
      elem.find('.pagination').addClass('pagination-sm');
    };

    var $this = $(this.tableTarget);
    var options = $.extend({
      responsive: true,
      aaSorting: [],
      dom: "<'row mx-1'<'col-sm-12 col-md-6 px-3'l><'col-sm-12 col-md-6 px-3'f>>" + "<'table-responsive'tr>" + "<'row mx-1 align-items-center justify-content-center justify-content-md-between'<'col-auto mb-2 mb-sm-0'i><'col-auto'p>>"
    }, $this.data('options'));
    $this.DataTable(options);
    var $wrpper = $this.closest('.dataTables_wrapper');
    customDataTable($wrpper);
    $this.on('draw.dt', function () {
      return customDataTable($wrpper);
    });

    $(this.tableTarget).DataTable().page(this.dtPage).draw(false);
  }
}
