import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]
  static values = { }

  initialize() {
  }

  connect() {
  }

  remove_lines()
  {
    var vals = $(".checkbox-bulk-select-target:checked").serializeArray();
    for(var val of vals)
    {
      console.log(val);
      $("<input>", {
        type: "hidden",
        name: val["name"],
        value: val["value"]
      }).appendTo("#remove-form");
    }
    $('#remove-form').submit();
  }

  clear_lines()
  {
    var vals = $(".checkbox-bulk-select-target:checked").serializeArray();
    for(var val of vals)
    {
      console.log(val);
      $("<input>", {
        type: "hidden",
        name: val["name"],
        value: val["value"]
      }).appendTo("#clear-form");
    }
    $('#clear-form').submit();
  }
}
