// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import * as ActionCable from '@rails/actioncable'
import "channels"
import "controllers"

import "bootstrap";
import Tribute from "@gitlab/tributejs";

import "@fortawesome/fontawesome-free/js/all.min";

import Stickyfill from "stickyfilljs/dist/stickyfill.min";
global.Stickyfill = Stickyfill;
// import "sticky-kit/dist/sticky-kit.min";
import is from "is_js/is.min";
global.is = is;
import "lodash/lodash.min";
import "perfect-scrollbar/dist/perfect-scrollbar.min";

import "datatables/media/js/jquery.dataTables.min";
import "datatables.net-bs4/js/dataTables.bootstrap4.min";
import "datatables.net-responsive/js/dataTables.responsive.js";
import "datatables.net-responsive-bs4/js/responsive.bootstrap4.js";

import "dropzone/dist/dropzone.js";
import toastr from "toastr/build/toastr.min.js";
import flatpickr from "flatpickr/dist/flatpickr.min.js";
import select2 from "select2/dist/js/select2.min.js";
import Typed from "typed.js/src/typed";
let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;

import "lib/config.navbar-vertical";
import "lib/theme";

Rails.start()
// Turbolinks.start()
ActiveStorage.start()
ActionCable.logger.enabled = true

global.Rails = Rails;
global.$ = jQuery;
global.Tribute = Tribute;
global.ActionCable = ActionCable;
global.toastr = toastr;
global.Typed = Typed;

toastr.options = {
  "newestOnTop": true,
  "progressBar": true,
  "positionClass": "toast-bottom-right",
  "preventDuplicates": false,
  "showDuration": "300",
  "hideDuration": "300",
  "timeOut": "1000",
}
