import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select" ]
  static values = { }

  initialize() {
  }

  connect() {
  }

  submit() {
    let val = this.selectTarget.value;
    console.log(val);
    if(val != "0")
    {
      let form = this.selectTarget.closest("form");
      form.action = form.action.replace(/audit_fields\/\d+\/change/, `audit_fields/${val}/change`);
      form.requestSubmit()
    }
  }
}
