import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "debit", "credit", "totalDebit", "totalCredit", "row" ]
  static values = { }

  initialize() {
  }

  connect() {
    this.calculate();
  }

  calculate()
  {
    let totalDebit = 0;
    for(var debit of this.debitTargets){
      if(parseInt(debit.value))
        totalDebit += parseInt(debit.value);
    }
    this.totalDebitTarget.innerHTML = totalDebit;

    let totalCredit = 0;
    for(var credit of this.creditTargets){
      if(parseInt(credit.value))
        totalCredit += parseInt(credit.value);
    }
    this.totalCreditTarget.innerHTML = totalCredit;

    if(totalDebit != totalCredit){
      this.rowTarget.classList.add("bg-soft-danger");
      this.rowTarget.classList.add("text-danger");
    }
    else{
      this.rowTarget.classList.remove("bg-soft-danger");
      this.rowTarget.classList.remove("text-danger");
    }
  }
}
