import consumer from "./consumer"

consumer.subscriptions.create("ChatChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // if chat is open, append to conversation if i don't have the message
    var current_user = $("#current-user").val();
    
    if(!$(`#message-${data["chat_message_id"]}`).length)
    {
      if(data["chat_user"] != current_user)
        $(`#chat-${data["chat_id"]}`).append(data["body"]);
      else
      $(`#chat-${data["chat_id"]}`).append(data["current_body"]);
      var scroll = document.getElementsByClassName("chat-content-scroll-area")[0];
      if(scroll)
        scroll.scrollTop = scroll.scrollHeight;
    }
    
    if(data["chat_user"] != current_user && data["chat_users"].indexOf(parseInt(current_user)) != -1)
    {
      console.log("found");
      // show toaster if incoming message
      $("#chat-notifications").html(data["notification"]);
      $(".chat-toast").toast({"delay": 3000});
      $(".chat-toast").toast("show");
      // if on chat index, mark user have unread messages
      console.log(data["related"]);
      if(data["related"])
        $(`#chat-file-${data["related"]}`).addClass("unread-message");
      else
        $(`#chat-user-${data["chat_user"]}`).addClass("unread-message");

      // change notification icon in nav to indicate new messages
      $("#nav-chats").addClass("notification-indicator notification-indicator-warning");
    }
  }
});
